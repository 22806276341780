<template>
  <div class="conference-page">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item> 会议展览 </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="news-wrapper">
      <div class="news-bar">
        <div class="bar-title">
          <img src="../assets/images/hygl.png" alt="" />
          <span>会议展览</span>
        </div>
        <ul class="bars" @click="changeList">
          <li class="bars-item" data-tid="1">会议通知</li>
          <li class="bars-item" data-tid="2">会展报道</li>
          <li class="bars-item" data-tid="3">主题活动</li>
          <li class="bars-item" data-tid="4">行业信息</li>
          <li class="bars-item" data-tid="5">参会报名</li>
        </ul>
      </div>
      <div class="news-list">
        <h3>
          <span>{{ barTitle }}</span>
        </h3>
        <ul class="list" v-if="list.count !== 0">
          <li
            v-for="i in list.list"
            :key="i.id"
            @click="$router.push(`/details?sid=${i.id}`)"
          >
            <span class="title">{{ i.title }}</span>
            <span class="time">{{ i.dates }}</span>
          </li>
        </ul>
        <el-pagination
          v-if="!list.count === 0"
          :current-page="currentPage"
          layout="prev,pager,next"
          :total="list.count"
          :page-size="10"
          background
          prev-text="上一页"
          next-text="下一页"
          @current-change="pageChange"
        >
        </el-pagination>
        <div
          class="news-details"
          v-if="list.count === 0"
          style="text-align: center; font-size: 20px; margin-top: 20px"
        >
          暂无会议信息
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      barTitle: "会议通知",
      list: {},
      tid: 1,
      currentPage: 1,
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.getList();
  },
  methods: {
    changeList(e) {
      if (e.target.tagName !== "LI") return;
      this.barTitle = e.target.innerText;
      let tid = e.target.dataset.tid;
      this.tid = tid;
      this.getList(this.currentPage, this.tid);
    },
    async getList(page = 1, tid = 1) {
      let { data } = await this.axios.get(
        `/index/meeting_list?page=${page}&type_id=${tid}`
      );
      this.list = data;
    },
    pageChange(page) {
      this.getList(page, this.tid);
    },
  },
};
</script>
<style lang="scss" scoped>
.conference-page {
  .news-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 30px;
    display: flex;
    padding-bottom: 310px;
    .news-bar {
      width: 230px;
      max-height: 370px;

      padding: 24px 20px 60px;
      background-color: #0168ad;
      color: #b1daf5;

      box-sizing: border-box;
      .bar-title {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-left: 10px;
        }
      }
      .bars {
        width: 190px;
        list-style: none;
        padding: 0;
        margin: 15px auto 0;
        display: flex;
        flex-direction: column;
        user-select: none;
        .bars-item {
          width: 100%;
          height: 38px;
          line-height: 38px;
          margin-top: 10px;
          color: #333;
          background-color: #fff;
          cursor: pointer;
          text-align: center;
          &:hover {
            color: #fff;
            background-color: #e8291d;
          }
        }
      }
    }
    .news-list {
      width: calc(1200px - 230px);
      // height: 100%;
      // float: right;
      padding: 20px;
      box-sizing: border-box;
      h3 {
        margin: 0;

        font-size: 18px;
        font-weight: normal;
        border-bottom: 3px solid #e2e2e2;
        padding: 5px;
        box-sizing: border-box;
        span {
          position: relative;
          &::after {
            content: "";
            display: block;
            position: absolute;
            left: -5px;
            bottom: -7px;
            width: 115%;
            height: 3px;
            background-color: #125e9e;
          }
        }
      }
      .list {
        list-style: none;

        li {
          height: 34px;
          line-height: 34px;
          position: relative;
          &::before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            display: block;
            background-color: #000;
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
          }
          .title {
            display: inline-block;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
            text-overflow: ellipsis;
            &:hover {
              color: #e8291d;
            }
          }
          .time {
            float: right;
          }
        }
      }
      .el-pagination {
        text-align: center;
        margin-top: 36px;
      }
    }
  }
}
</style>
<style lang="scss">
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
